import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";

//components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import HeroChevron from "../../../components/hero/hero-chevron";
import SEO from "../../../components/seo/seo";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const SpanishBlog = () => {
  const data = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/money-tips/hero-blogs-04272023-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  // Get all cards from spanish es-card-blogs file by passing empty slug array
  const allCardsData = useCardBlogsData([], { isSpanish: true });
  // Sort cards by date attribute
  allCardsData.sort((a, b) => new Date(b.date) - new Date(a.date));
  // Use getFeaturedCardBlogData to convert the data into stretchlink valid objects
  const allFeaturedCardsData = getFeaturedCardBlogData(allCardsData, { textAttr: "description" });
  // Slice first 3 cards, those will be shown on the latest monet tips section
  const latestCardData = allFeaturedCardsData.slice(0, 3);
  const remainderCards = allFeaturedCardsData.slice(3).map((card) => ({ ...card, showTextArrow: true }));

  const heroChevronData = {
    id: "blog-hero",
    ...getHeroImgVariables(data),
    altText: "Man sitting on stairs outside using his smartphone.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "¿Necesitas Consejos de Dinero?, Te tenemos cubierto."
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn",
            text: "Ve los Últimos Consejos de Dinero",
            url: "#latest-money-tips-section",
            class: "btn-white",
            type: "anchor-link"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Consejos de dinero"
    }
  ];

  const SEOData = {
    title: "Consejos de dinero",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: "Consejos de dinero"
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Consejos de dinero WaFd ¿Necesita consejos de dinero para comprar una casa o crear un presupuesto familiar? Te cubrimos."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/tu-decides"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-blogs-04272023.jpg"
      }
    ]
  };

  const latestMoneyTipsData = {
    sectionClass: "bg-light",
    sectionId: "latest-money-tips-section",
    titleClass: "text-center",
    title: "Nuestros últimos consejos de dinero",
    hasGrowEffect: true,
    cards: latestCardData
  };

  const indexEsBlogSection = {
    sectionId: "index-es-blogs-section",
    sectionClass: "pt-0 bg-light",
    cards: remainderCards
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>WaFd Consejos de dinero</h1>
        <h4>
          Seleccione cualquier articulo de abajo para aprender como WaFd Money Tips le puede ayudar con sus metas
          financieras.
        </h4>
      </section>
      <StretchedLinkCards {...latestMoneyTipsData} />
      <StretchedLinkCards {...indexEsBlogSection} />
    </SecondaryLanding>
  );
};

export default SpanishBlog;
